export function toast() {
  return {
    message: "default message",
    type: "default",
    visible: false,
    // Type is set up for future use, displaying certain colors based on type of toast
    popToast(message, type = "default") {
      this.message = message
      this.type = type
      // delay 3 seconds
      this.visible = true
      setTimeout(() => {
        this.visible = false
      }, 3000)
    },
    closeToast() {
      this.visible = false
    },
  }
}
